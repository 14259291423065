// Main Module Routes:-

import MainMenuEntity from "@/views/Dashboard/MainModule/MainMenu/MainMenuEntity.vue";
import MainMenuControl from "@/views/Dashboard/MainModule/MainMenu/MainMenuControl.vue";
import ScreenEntity from "@/views/Dashboard/MainModule/Screens/ScreenEntity.vue";
import ScreenControl from "@/views/Dashboard/MainModule/Screens/ScreenControl.vue";
import UserEntity from "@/views/Dashboard/MainModule/Users/UserEntity.vue";
import UserControl from "@/views/Dashboard/MainModule/Users/UserControl.vue";
import SubscriptionsEntityVue from "@/views/Dashboard/MainModule/Subscriptions/SubscriptionsEntity.vue";
import SubscriptionsControlVue from "@/views/Dashboard/MainModule/Subscriptions/SubscriptionsControl.vue"
import PermissionsEntity from "@/views/Dashboard/MainModule/PermissionsEntity/PermissionsEntity";
import PermissionsControl from "@/views/Dashboard/MainModule/PermissionsEntity/PermissionsControl";
import JobTitleEntity from "@/views/Dashboard/MainModule/JobTitle/JobTitleEntity";
import JobTitleControl from "@/views/Dashboard/MainModule/JobTitle/JobTitleControl";
import GeneralSettings from "@/views/Dashboard/MainModule/GeneralSettings/GeneralSettings";

export const MainModuleRoutes = [
  // Main Menu
  {
    path: "/main/main_menu",
    name: "MainMenuEntity",
    component: MainMenuEntity,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/main_menu-control",
    name: "ScreenControl",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/main_menu-control/:id",
    name: "MainMenuControlEdit",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  // Screens
  {
    path: "/main/screen_sub",
    name: "ScreenEntity",
    component: ScreenEntity,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_sub-control",
    name: "ScreenControl",
    component: ScreenControl,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_sub-control/:id",
    name: "ScreenControlEdit",
    component: ScreenControl,
    meta: {
      screen_code: "01-002",
    },
  },
  // Users
  {
    path: "/main/user",
    name: "UserEntity",
    component: UserEntity,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/user-control",
    name: "UserControl",
    component: UserControl,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/user-control/:id",
    name: "UserControlEdit",
    component: UserControl,
    meta: {
      screen_code: "01-003",
    },
  },
  // Subscriptions
  {
    path: "/main/subscription",
    name: "SubscriptionsEntity",
    component: SubscriptionsEntityVue,
    meta: {
      screen_code: "06-001",
    },
  },
  {
    path: "/main/subscription-control",
    name: "SubscriptionsControl",
    component: SubscriptionsControlVue,
    meta: {
      screen_code: "06-001",
    },
  },
  {
    path: "/main/subscription-control/:id",
    name: "SubscriptionsControlVueEdit",
    component: SubscriptionsControlVue,
    meta: {
      screen_code: "06-001",
    },
  },
    // permissions
    {
      path: "/main/user_permission",
      name: "PermissionsEntity",
      component: PermissionsEntity,
      meta: {
        screen_code: "01-004",
      },
    },
    {
      path: "/main/permissions-control/:id",
      name: "PermissionsControl",
      component: PermissionsControl,
      meta: {
        screen_code: "01-004",
      },
    },
    // Job Title 
    {
      path: "/main/title",
      name: "JobTitleEntity",
      component: JobTitleEntity,
      meta: {
        screen_code: "01-005",
      },
    },
    {
      path: "/main/title-control",
      name: "JobTitleControl",
      component: JobTitleControl,
      meta: {
        screen_code: "01-005",
      },
    },
    {
      path: "/main/title-control/:id",
      name: "JobTitleControlEdit",
      component: JobTitleControl,
      meta: {
        screen_code: "01-005",
      },
    },
      // General Settings
      {
        path: "/main/settings/:id",
        name: "GeneralSettings",
        component: GeneralSettings,
        meta: {
          screen_code: "01-010",
        },
      }, 
];
