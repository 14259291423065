<template>
  <v-container class="d-flex justify-center" fluid style="transition : 0.35s">
    <v-col cols="12" sm="12" class="pb-0">
      <v-row align="center" justify="space-between">
        <v-col cols="12" md="3" sm="6">
          <v-card class="mx-auto rounded-xl" color="#fdba84" dark elevation="0">
            <v-card-title>
              <span class="text-h6">{{$t("Cement")}}</span>
            </v-card-title>
            <img src="@/assets/img/svg/cement.svg" class="float-icon" alt="Cement Egypt" />
            <v-card-text class="text-h5 font-weight-bold">1,084 <small>{{$t("LE")}}/{{$t("KG")}}</small></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card class="mx-auto rounded-xl" color="#4f6aef"  dark elevation="0">
            <v-card-title>
              <span class="text-h6">{{$t("Gypsum")}}</span>
            </v-card-title>
            <img src="@/assets/img/svg/gypsum.svg" class="float-icon" alt="Cement Egypt" />
            <v-card-text class="text-h5 font-weight-bold">984.30  <small>{{$t("LE")}}/{{$t("KG")}}</small></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card class="mx-auto rounded-xl" color="#35c2fc" dark elevation="0">
            <v-card-title>
              <span class="text-h6">{{$t("Metal")}}</span>
            </v-card-title>
            <img src="@/assets/img/svg/metal.svg" class="float-icon" alt="Cement Egypt" />
            <v-card-text class="text-h5 font-weight-bold">884.30 <small>{{$t("LE")}}/{{$t("KG")}}</small></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card class="mx-auto rounded-xl" color="#50dfb3" dark elevation="0">
            <v-card-title>
              <span class="text-h6">{{$t("Transport")}}</span>
            </v-card-title>
            <img src="@/assets/img/svg/transport.svg" class="float-icon" alt="Cement Egypt" />
            <v-card-text class="text-h5 font-weight-bold">2,500 <small>{{$t("LE")}}/{{$t("KM")}}</small></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<style scoped  lang="scss">
.float-icon {
  position: absolute !important;
  bottom: 9px;
  right: 9px;
  opacity: 1;
  font-size: 58px !important;
}
.v-application--is-rtl {
  .float-icon {
    right: unset;
    left: 9px;
  }
}
</style>






<script>
export default {
  name: "StatisticsBar",
  // props: ["summary"],
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      summary: [
        {

        }
      ]
    };
  },
};
</script>
