// Prices Module Routes:-
import PricesEntity from "@/views/Dashboard/PricesModule/Prices/PricesEntity.vue";
import PricesControl from "@/views/Dashboard/PricesModule/Prices/PricesControl.vue";

import ItemsEntity from "@/views/Dashboard/PricesModule/Items/ItemsEntity.vue";
import ItemsControl from "@/views/Dashboard/PricesModule/Items/ItemsControl.vue";
import GovernoratesEntity from "@/views/Dashboard/PricesModule/Governorates/GovernoratesEntity.vue";
import GovernoratesControl from "@/views/Dashboard/PricesModule/Governorates/GovernoratesControl.vue";
import ProducersEntity from "@/views/Dashboard/PricesModule/Producers/ProducersEntity.vue";
import ProducersControl from "@/views/Dashboard/PricesModule/Producers/ProducersControl.vue";
import ProductTypesEntity from "@/views/Dashboard/PricesModule/ProductTypes/ProductTypesEntity.vue";
import ProductTypesControl from "@/views/Dashboard/PricesModule/ProductTypes/ProductTypesControl.vue";
import ProductsEntity from "@/views/Dashboard/PricesModule/Products/ProductsEntity.vue";
import ProductsControl from "@/views/Dashboard/PricesModule/Products/ProductsControl.vue";
export const PricesModuleRoutes = [
 
  // Prices
  {
    path: "/prices/price",
    name: "PricesEntity",
    component: PricesEntity,
    meta: {
      screen_code: "05-006",
    },
  },
  {
    path: "/prices/price-control",
    name: "PricesControl",
    component: PricesControl,
    meta: {
      screen_code: "05-006",
    },
  },
  {
    path: "/prices/price-control/:id",
    name: "PricesControlEdit",
    component: PricesControl,
    meta: {
      screen_code: "05-006",
    },
  },
   // Items
   {
    path: "/prices/item",
    name: "ItemsEntity",
    component: ItemsEntity,
    meta: {
      screen_code: "05-005",
    },
  },
  {
    path: "/prices/item-control",
    name: "ItemsControl",
    component: ItemsControl,
    meta: {
      screen_code: "05-005",
    },
  },
  {
    path: "/prices/item-control/:id",
    name: "ItemsControlEdit",
    component: ItemsControl,
    meta: {
      screen_code: "05-005",
    },
  },
  // Governorates
  {
    path: "/prices/governorate",
    name: "GovernoratesEntity",
    component: GovernoratesEntity,
    meta: {
      screen_code: "05-004",
    },
  },
  {
    path: "/prices/governorate-control",
    name: "GovernoratesControl",
    component: GovernoratesControl,
    meta: {
      screen_code: "05-004",
    },
  },
  {
    path: "/prices/governorate-control/:id",
    name: "GovernoratesControlEdit",
    component: GovernoratesControl,
    meta: {
      screen_code: "05-004",
    },
  },
  // Producers
  {
    path: "/prices/producer",
    name: "ProducersEntity",
    component: ProducersEntity,
    meta: {
      screen_code: "05-002",
    },
  },
  {
    path: "/prices/producer-control",
    name: "ProducersControl",
    component: ProducersControl,
    meta: {
      screen_code: "05-002",
    },
  },
  {
    path: "/prices/producer-control/:id",
    name: "ProducersControlEdit",
    component: ProducersControl,
    meta: {
      screen_code: "05-002",
    },
  },
  // Product Type
  {
    path: "/prices/product_type",
    name: "ProductTypesEntity",
    component: ProductTypesEntity,
    meta: {
      screen_code: "05-003",
    },
  },
  {
    path: "/prices/product_type-control",
    name: "ProductTypesControl",
    component: ProductTypesControl,
    meta: {
      screen_code: "05-003",
    },
  },
  {
    path: "/prices/product_type-control/:id",
    name: "ProductTypesControlEdit",
    component: ProductTypesControl,
    meta: {
      screen_code: "05-003",
    },
  },
  // Products
  {
    path: "/prices/product",
    name: "ProductsEntity",
    component: ProductsEntity,
    meta: {
      screen_code: "05-001",
    },
  },
  {
    path: "/prices/product-control",
    name: "ProductsControl",
    component: ProductsControl,
    meta: {
      screen_code: "05-001",
    },
  },
  {
    path: "/prices/product-control/:id",
    name: "ProductsControlEdit",
    component: ProductsControl,
    meta: {
      screen_code: "05-001",
    },
  },

];
