// Website Module Routes:-

import AdsEntity from "@/views/Dashboard/WebsiteModule/Ads/AdsEntity.vue";
import AdsControl from "@/views/Dashboard/WebsiteModule/Ads/AdsControl.vue";
import NewsEntity from "@/views/Dashboard/WebsiteModule/News/NewsEntity.vue";
import NewsControl from "@/views/Dashboard/WebsiteModule/News/NewsControl.vue";
import StaticPagesEntity from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesEntity.vue";
import StaticPagesControl from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesControl.vue";
import SlidersEntity from "@/views/Dashboard/WebsiteModule/Sliders/SlidersEntity.vue";
import SlidersControl from "@/views/Dashboard/WebsiteModule/Sliders/SlidersControl.vue";
import NewsCategoryEntityVue from "../views/Dashboard/WebsiteModule/NewsCategory/NewsCategoryEntity.vue";
import NewsCategoryControlVue from "../views/Dashboard/WebsiteModule/NewsCategory/NewsCategoryControl.vue";
import ContactUsEntity from "../views/Dashboard/WebsiteModule/ContactUs/ContactUsEntity.vue";
import ContactUsControl from "../views/Dashboard/WebsiteModule/ContactUs/ContactUsControl.vue";
import WebsiteBasicsControl from "../views/Dashboard/WebsiteModule/WebsiteBasics/WebsiteBasicsControl.vue";
import ProfilesEntity from "@/views/Dashboard/WebsiteModule/Profiles/ProfilesEntity.vue";
import ProfilesControl from "@/views/Dashboard/WebsiteModule/Profiles/ProfilesControl.vue";

export const WebsiteModuleRoutes = [
  // Ads
  {
    path: "/website/ads",
    name: "AdsEntity",
    component: AdsEntity,
    meta: {
      screen_code: "02-001",
    },
  },
  {
    path: "/website/ads-control",
    name: "AdsControl",
    component: AdsControl,
    meta: {
      screen_code: "02-001",
    },
  },
  {
    path: "/website/ads-control/:id",
    name: "AdsControlEdit",
    component: AdsControl,
    meta: {
      screen_code: "02-001",
    },
  },

  // News Category
  {
    path: "/website/news_category",
    name: "NewsCategoryEntity",
    component: NewsCategoryEntityVue,
    meta: {
      screen_code: "02-005",
    },
  },
  {
    path: "/website/news_category-control",
    name: "NewsCategoryControlVue",
    component: NewsCategoryControlVue,
    meta: {
      screen_code: "02-005",
    },
  },
  {
    path: "/website/news_category-control/:id",
    name: "NewsCategoryControlVueEdit",
    component: NewsCategoryControlVue,
    meta: {
      screen_code: "02-005",
    },
  },
  // News
  {
    path: "/website/news",
    name: "NewsEntity",
    component: NewsEntity,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/website/news-control",
    name: "NewsControl",
    component: NewsControl,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/website/news-control/:id",
    name: "NewsControlEdit",
    component: NewsControl,
    meta: {
      screen_code: "02-002",
    },
  },
  // Static Pages
  {
    path: "/website/static_page",
    name: "StaticPagesEntity",
    component: StaticPagesEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/website/static_page-control",
    name: "StaticPagesControl",
    component: StaticPagesControl,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/website/static_page-control/:id",
    name: "StaticPagesControlEdit",
    component: StaticPagesControl,
    meta: {
      screen_code: "02-003",
    },
  },
  // Sliders
  {
    path: "/website/slider",
    name: "SlidersEntity",
    component: SlidersEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/website/slider-control",
    name: "SlidersControl",
    component: SlidersControl,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/website/slider-control/:id",
    name: "SlidersControlEdit",
    component: SlidersControl,
    meta: {
      screen_code: "02-004",
    },
  },
  // WebsiteBasics
  {
    path: "/website/website_basic_data",
    name: "WebsiteBasicsControl",
    component: WebsiteBasicsControl,
    meta: {
      screen_code: "02-006",
    },
  },
  // Contact Us
  {
    path: "/website/contact_us",
    name: "ContactUsEntity",
    component: ContactUsEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/contact_us-control",
    name: "ContactUsControl",
    component: ContactUsControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/contact_us-control/:id",
    name: "ContactUsControlEdit",
    component: ContactUsControl,
    meta: {
      screen_code: "02-007",
    },
  },
  // Profiles
  {
    path: "/website/profile",
    name: "ProfilesEntity",
    component: ProfilesEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/website/profile-control",
    name: "ProfilesControl",
    component: ProfilesControl,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/website/profile-control/:id",
    name: "ProfilesControlEdit",
    component: ProfilesControl,
    meta: {
      screen_code: "02-008",
    },
  },
];
