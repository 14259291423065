<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" md="6" lg="4" class="px-0">
                     <GenericInput type="file" :value="form.image_url" @input="form.image_url = $event"
                        :cols="[12, 12, 12]" label="user image" :multi="false" :required="true"
                        :isLoading="pageData.editIsLoading">
                     </GenericInput>
                  </v-col>
                  <v-col cols="1" class="pa-0 py-10 d-none d-md-block">
                     <v-divider vertical class="d-block ma-auto"></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="7" class="d-flex align-center">
                     <v-row align="center">
                        <GenericInput type="text" :value="form.full_name_en" @input="form.full_name_en = $event"
                           label="full name en" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>
                        <GenericInput type="text" :value="form.full_name_ar" @input="form.full_name_ar = $event"
                           label="الأسم كامل عربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>
                        <GenericInput type="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobile"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                        <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                        <GenericInput type="text" :value="form.username" @input="form.username = $event"
                           label="username" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="password" v-if="!pageData.isEdit" :value="form.password"
                           @input="form.password = $event" label="password" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                        <GenericInput type="select" :lookups="titles" :value="form.title_id"
                           @input="form.title_id = $event" label="job" selected_label="title" selected_prop="id"
                           :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                        </GenericInput>
                     </v-row>
                  </v-col>


               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "usersControl",
   data: () => ({
      pageData: {
         screen_code: "01-003",
         url: null,
         controlRoute: "main/user-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      sales_agents: [],
      branches: [],
      titles: [],
      years: [],
      form: {
         full_name_en: null,
         full_name_ar: null,
         username: null,
         title_id: null,
         mobile: null,
         email: null,
         password: null,
         image_url: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('user') : this.$i18n.t('add new user');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`user/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.titles = response.data.titles;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`user/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.titles = response.data.titles;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`user/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`user`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`user/${this.form.user_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
