<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="d-flex align-center">
          <v-avatar size="26px">
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-avatar>
          <div class="text-start">
            <h5 class="text-capitalize d-none d-lg-block mx-2">{{ $store.state.userData.full_name }} <v-icon small class="mx-1">mdi-chevron-down</v-icon>
            </h5>
            <h5 class="text--disabled d-none body-2 d-lg-block mx-2">{{ $store.state.userData.title }}</h5>
          </div>

        </div>
      </template>

      <v-card style="max-width: 300px" class="py-0 rounded-0">
        <v-list-item-content class="justify-center">

          <div class="d-flex justify-space-between px-4 align-center text-center">
            <v-avatar color="light">
              <img v-if="$store.state.userData.image" class="pa-1" :src="$api.serverUrl + $store.state.userData.image"
                :alt="$store.state.userData.full_name" />
              <v-icon v-if="!$store.state.userData.image">mdi-file-image-outline</v-icon>
            </v-avatar>
            <div style="width: 75%;" class="text-start">
              <h5 class="mx-2 my-1">{{ $store.state.userData.full_name }}</h5>
              <h5 class="mx-2 my-1 text--disabled">{{ $store.state.userData.title }}</h5>
              <h5 class="mx-2 my-1 text--disabled">{{ $t("company number") }} : {{ $store.state.clientID }}</h5>
            </div>
          </div>
          <div>
            <v-divider class="my-2"></v-divider>
            <div class="userList d-flex justify-space-between px-4 align-center text-center"
              @click="changePassDialog = true">
              <v-avatar color="transparent">
                <img src="../../assets/img/svg/changePass.svg" alt />
              </v-avatar>
              <div style="width: 75%;" class="text-start">
                <h5 class="mx-2 my-1">{{ $t("change password") }}</h5>
              </div>
            </div>
            <v-divider v-if="login_date" class="my-2"></v-divider>
            <div v-if="login_date" class="d-flex justify-space-between px-4 align-center text-center">
              <v-avatar color="transparent">
                <img src="../../assets/img/svg/lastLogin.svg" alt />
              </v-avatar>
              <div style="width: 75%;" class="text-start">
                <h5 class="mx-2 my-1">{{ $t("last login") }}</h5>
                <h5 class="mx-2 my-1 text--disabled">
                  {{
                      new Date(login_date).toLocaleDateString(
                        `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                        {
                          weekday: "short",
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric"
                        }
                      )
                  }}
                </h5>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="userList d-flex justify-space-between px-4 align-center text-center" @click="$api.LOGOUT(true)">
              <v-avatar color="transparent">
                <img src="../../assets/img/svg/logout.svg" alt />
              </v-avatar>
              <div style="width: 75%;" class="text-start">
                <h5 class="mx-2 my-1">{{ $t("logout") }}</h5>
              </div>
            </div>
            <v-divider class="my-2 d-md-none"></v-divider>
            <div class="userList d-flex d-md-none justify-space-between px-4 align-center text-center" v-on:click="changeLanguage">
              <v-avatar color="transparent">
                <img src="@/assets/img/svg_icons/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="cement" />
                <img src="@/assets/img/svg_icons/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="cement" />
              </v-avatar>
              <div style="width: 75%;" class="text-start">
                <h5 class="mx-2 my-1">{{$vuetify.rtl == true ? 'English' : 'عربي' }}</h5>
              </div>
            </div>
            <v-divider class="my-2 d-md-none"></v-divider>
            <div class="userList d-flex d-md-none justify-space-between px-4 align-center text-center" @click="darkTheme" >
              <v-avatar color="transparent">
                <v-icon v-if="$store.state.Settings.darkTheme" color="yellow1">mdi-white-balance-sunny</v-icon>
                <v-icon v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
              </v-avatar>
              <div style="width: 75%;" class="text-start">
                <h5 class="mx-2 my-1">{{$store.state.Settings.darkTheme? $t('light theme') : $t('dark theme') }}</h5>
              </div>
            </div>
          </div>
          <ChangePasswordModalVue :dialog="changePassDialog" :backValueMethod="close"></ChangePasswordModalVue>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>
<style scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px; 
}

#appBarMenu .v-menu__content {
  top: 64px !important;
  box-shadow: none !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}

.userList {
  cursor: pointer;
}
</style>

<script>
import ChangePasswordModalVue from "../modals/ChangePasswordModal.vue";
export default {
  name: "UserMenu",
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  components: { ChangePasswordModalVue },
  data: () => ({
    user: {},
    login_date: null,
    changePassDialog: false,
  }),
  mounted() {
    this.login_date = localStorage.getItem("login_date");
  },

  methods: {
    close(status) {
      this.changePassDialog = status;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },
};
</script>
