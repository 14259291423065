<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" md="6" lg="4" class="px-0 ">
                     <GenericInput type="file" :value="form.photo_ar" paddingY="py-2" @input="form.photo_ar = $event"
                        :cols="[12, 12, 12]" label="صوره سلايدر العربي" :multi="false" :required="true"
                        :isLoading="pageData.editIsLoading">
                     </GenericInput>
                     <GenericInput type="file" :value="form.photo_en" paddingY="py-2" @input="form.photo_en = $event"
                        :cols="[12, 12, 12]" label="english slider image" :multi="false" :required="true"
                        :isLoading="pageData.editIsLoading">
                     </GenericInput>
                  </v-col>
                  <v-col cols="1" class="pa-0 py-10 d-none d-md-block">
                     <v-divider vertical class="d-block ma-auto"></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="7" class="d-flex align-start">
                     <v-row align="center">
                        <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event"
                           label="العنوان بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                        <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event"
                           label="title in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
     
                        <GenericInput type="text" :value="form.link_ar" @input="form.link_ar = $event"
                           label="الرابط العربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.link_en" @input="form.link_en = $event"
                           label="english link" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                        </GenericInput>


                     </v-row>
                  </v-col>


               </v-row>


            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "SlidersControl",
   data: () => ({
      pageData: {
         screen_code: "02-004",
         url: null,
         controlRoute: "website/slider-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      transactions: [],
      form: {
         title_en: null,
         title_ar: null,
         link_en: null,
         link_ar: null,
         photo_en: null,
         photo_ar: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.formatDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`slider/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`slider/create`).then(() => {
                  this.pageData.isLoading = false;
                  // if (response.check) {
    

                  // }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`slider/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`slider`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      formatDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         this.form.start_date = [year, month, day].join('-');
         this.form.end_date = [year, month, day].join('-');
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`slider/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
