<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" createBtnText="multiple modification" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12">
                     <div class="font-weight-bold body-1">{{ $t('item details') | capitalize }}:</div>
                  </v-col>

                  <GenericInput type="select" :lookups="products" :value="form.product_id"
                     @input="form.product_id = $event;" :multi="false" :required="true" selected_label="name"
                     label="product" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />

                  <GenericInput type="select" :lookups="filtered_products_types" :value="form.product_type_id"
                     @input="form.product_type_id = $event;" :multi="false" :required="true" selected_label="name"
                     :label="!form.product_id ? 'product type (select product first)' : 'product type'"
                     :disabled="!(form.product_id)" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]" />


                  <GenericInput type="select" :lookups="producers" :value="form.producer_id"
                     @input="form.producer_id = $event;" :multi="false" :required="true" selected_label="name"
                     label="producer" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />

                  <GenericInput type="select" :lookups="filtered_governorates" :value="form.govs"
                     @input="form.govs = $event;" :multi="!(pageData.isEdit)" :required="true" selected_label="name"
                     label="governorate" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />
                  <GenericInput type="select" :clearable="true"
                     :disabled="!(form.product_id && form.product_type_id && form.producer_id)"
                     :lookups="filtered_items" :value="form.item_id" @input="form.item_id = $event"
                     :label="!(form.product_id && form.product_type_id && form.producer_id) ? 'item (select product - type - producer first)' : 'item'"
                     selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />

                  <v-col cols="12" class="px-16">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                     <div class="font-weight-bold body-1">{{ $t('price details') | capitalize }}:</div>
                     <div class="font-weight-bold body-2 mx-2 gray8--text" v-if="pageData.isEdit">
                        (
                        {{ $t('medium price yesterday') | capitalize }}: {{ form.mid_price_yesterday || 0 | float }}
                     </div>
                     <div class="font-weight-bold body-2 mx-2 gray8--text" v-if="pageData.isEdit">
                        {{ $t('medium price') | capitalize }}: {{ form.mid_price || 0 | float }} )
                     </div>

                  </v-col>

                  <GenericInput type="date" :value="form.from_date" @input="form.from_date = $event"
                     :maxDate="form.to_date || today" :isPickerOpened="() => form.to_date = null" label="from date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="date" :value="form.to_date" @input="form.to_date = $event"
                     :minDate="form.from_date" :maxDate="today" label="to date" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="float" :value="form.min_price" @input="form.min_price = $event"
                     label="minimum price" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>



                  <GenericInput type="float" :value="form.max_price" @input="form.max_price = $event"
                     label="maximum price" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "PricesControl",
   data: () => ({
      pageData: {
         screen_code: "05-006",
         url: null,
         controlRoute: "prices/price-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      today: null,
      items: [],
      governorates: [],
      producers: [],
      products: [],
      products_types: [],
      form: {
         item_id: null,
         from_date: null,
         to_date: null,
         min_price: 0,
         max_price: 0,
         govs: [],
         producer_id: null,
         product_id: null,
         product_type_id: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
      filtered_items() {
         return this.items.filter((item) => {
            if (this.form.govs.length) {
               if (
                  item['product_id'] == this.form.product_id
                  && item['product_type_id'] == this.form.product_type_id
                  && item['producer_id'] == this.form.producer_id
                  && item['governorates'].filter((gov) => this.form.govs.includes(gov)).length
               )
                  return item
            } else {
               if (
                  item['product_id'] == this.form.product_id
                  && item['product_type_id'] == this.form.product_type_id
                  && item['producer_id'] == this.form.producer_id
               )
                  return item
            }
         })
      },
      filtered_governorates() {
         if (this.form.item_id) {
            const getItem = this.filtered_items.filter((item) => item.id === this.form.item_id)[0]
            console.log(getItem);
            return this.governorates.filter((governorate) => {
               if (getItem.governorates.some((item_gov) => governorate.id == item_gov))
                  return governorate
            })
         } else {
            return this.governorates
         }

      },
      filtered_products_types() {
         return this.products_types.filter((type) => type['product_id'] == this.form.product_id)
      },
   },
   watch: {
      "form.product_id"() {
         if (this.pageData.isEdit == false) {
            this.form.product_type_id = null;
         }
      },
      "form.product_type_id"() {
         if (this.pageData.isEdit == false) {
            this.form.item_id = null;
         }
      },
      "form.producer_id"() {
         if (this.pageData.isEdit == false) {
            this.form.item_id = null;
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.today = this.$global.GetCurrentDate();
      this.form.to_date = this.$global.GetCurrentDate();
      this.form.from_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`price/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     // this.form.govs = this.form.govs.split(',').map(i => Number(i));
                     response.data.items.forEach(item => {
                        item.governorates = item.govs.split(',').map(i => +i)
                     });
                     this.items = response.data.items || [];
                     this.governorates = response.data.governorates || [];
                     this.producers = response.data.producers || [];
                     this.products = response.data.products || [];
                     this.products_types = response.data.products_types || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`price/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     response.data.items.forEach(item => {
                        item.governorates = item.govs.split(',').map(i => +i)
                     });
                     this.items = response.data.items || [];
                     this.governorates = response.data.governorates || [];
                     this.producers = response.data.producers || [];
                     this.products = response.data.products || [];
                     this.products_types = response.data.products_types || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`price/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`price`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url)
                     this.getData();
                     this.form = {
                        item_id: null,
                        price_date: null,
                        min_price: 0,
                        max_price: 0,
                        govs: [],
                        producer_id: null,
                        product_id: null,
                        product_type_id: null,
                     }
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`price/${this.form.price_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
