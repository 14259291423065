<template>
  <v-container class="py-0">
    <v-row justify="space-between" align="center">
      <v-col cols="auto" sm="auto" class="py-0">
        <v-pagination v-model="pagination.page"  total-visible="10" :disabled="isLoading" @input="returnActivePage"
          :length="pagination.totalPages" color="primary" circle></v-pagination>
      </v-col>
      <v-col cols="auto" sm="auto"  class="py-0">
        <div class="d-flex align-center">
          <small class="mx-0">{{ $t('view') | capitalize}}</small>
          <v-select solo  style="width: 80px; height: 45px;" class="mx-2  my-0 mt-2 " dense  v-model="limit"
            @change="backValueMethod(1, limit)" :items="[1, 10, 15, 20, 30, 50, 100]"></v-select>
          <small class="mx-0">{{ $t('from') | capitalize }}  {{ pagination.totalRows }}</small>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>




<style scoped lang="scss">
.v-pagination__item {
  box-shadow: none !important;
}
</style>



<script>
export default {
  name: "Pagination",
  props: {
    backValueMethod: { type: Function },
    pagination: { default: null },
    limit: { default: 10 },
    isLoading: { default: "" },
  },
  computed: {
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    returnActivePage(page) {
      this.backValueMethod(page, this.limit)
    }
  }
};
</script>
