<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
        <v-row align="center">

                        <v-col cols="12">
                           <div class="subtitle-1">{{ $t('social media data') }}</div>
                        </v-col>
                        <GenericInput type="mobile" :value="form.phone" @input="form.phone = $event" label="phone"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.whatsapp" @input="form.whatsapp = $event"
                           label="whatsapp" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.facebook_link" @input="form.facebook_link = $event"
                           label="facebook" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.linkedin_link" @input="form.linkedin_link = $event"
                           label="linkedin" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.twitter_link" @input="form.twitter_link = $event"
                           label="twitter" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.youtube_link" @input="form.youtube_link = $event"
                           label="youtube" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <v-col cols="12">
                           <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                           <div class="subtitle-1">{{ $t('about us') }}</div>
                        </v-col>


                        <GenericInput type="texteditor" :value="form.about_ar" @input="form.about_ar = $event"
                           label="عن اسمنت مصر بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="texteditor" :value="form.about_en" @input="form.about_en = $event"
                           label="about in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="texteditor" :value="form.mission_ar" @input="form.mission_ar = $event"
                           label="مهمه اسمنت مصر بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="texteditor" :value="form.mission_en" @input="form.mission_en = $event"
                           label="mission in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="texteditor" :value="form.vision_ar" @input="form.vision_ar = $event"
                           label="رؤية اسمنت مصر بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>

                        <GenericInput type="texteditor" :value="form.vision_en" @input="form.vision_en = $event"
                           label="vision in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]">
                        </GenericInput>


                     </v-row>


            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "AdsControl",
   data: () => ({
      pageData: {
         screen_code: "02-006",
         url: null,
         controlRoute: "website/website_basic_data",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      transactions: [],
      form: {
         about_ar: null,
         about_en: null,
         mission_ar: null,
         mission_en: null,
         vision_ar: null,
         vision_en: null,
         phone: null,
         email: null,
         whatsapp: null,
         facebook_link: null,
         linkedin_link: null,
         twitter_link: null,
         youtube_link: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`website_basic_data`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                        this.form = response.data.items[0]
                  }
               })
            } else {
               this.$router.push('/')
            }
      },
      save() {
         if (this.$refs.form.validate()) {
             this.form._method = 'PUT';
            this.pageData.isLoading = true;
             this.$api.POST_METHOD(`website_basic_data/1`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.getData()
                  }
               })
         }
      },

      deleteMethod() {

      },


   },
};
</script>
