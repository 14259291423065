<template>
  <v-card class="shadow rounded-lg recipeChart my-5 py-5 overflow-hidden">
    <v-row justify="center">
      <v-col cols="12" class="py-0 d-flex justify-center" id="chart">
      
        <apexchart
          type="area"
          height="320"
          :width="width"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 green rounded-pill"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">Highest</h5>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 red rounded-pill"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">Lowest</h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "AnalysisChart",
  computed: {},
  mounted() {
    this.onResize();

    this.width = document.getElementById("chart").offsetWidth - 75;
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
  data: () => ({
    windowSize: {},
    width: 0,
    analysis: ["Year analysis", "Month analysis"],
    views: ["Weeks", "Months", "Years"],
    activeAnalysis: "Year analysis",
    activeView: "Weeks",
    series: [
      {
        name: "Highest",
        data: [28, 29, 33, 36, 32, 32, 33],
      },
      {
        name: "Lowest",
        data: [12, 11, 14, 18, 17, 13, 13],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#2ed47a", "#f7685b"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: " ",
        align: "left",
      },
      grid: {
        borderColor: "transparent",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.1,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        title: {
          text: " ",
        },
      },
      yaxis: {
        title: {
          text: " ",
        },
        min: 5,
        max: 40,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  }),
};
</script>
