<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="filter"
        :actionBtn="!showFilter" :createBtn="!showFilter" :showDeleted="!showFilter" :showSearch="!showFilter"
        :actionBtnValid="true" actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }"
        actionBtnIcon="mdi-filter-variant" :actionBtnOutline="true" actionBtnClass="blue4--text"
        createBtnText="multiple modification" />
      <!-- End Of Header -->

      <!-- Filter -->
      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">

              <GenericInput type="date" paddingY="py-2" :maxDate="pageData.to_date || today" :clearable="true"
                :value="pageData.from_date" @input="pageData.from_date = $event" label="from date" :required="false"
                :hide-details="true" :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />

              <GenericInput type="date" paddingY="py-2" :minDate="pageData.from_date" :maxDate="today" :clearable="true"
                :value="pageData.to_date" @input="pageData.to_date = $event" label="to date" :required="false"
                :hide-details="true" :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />

              <GenericInput type="select" paddingY="py-2" :clearable="true" :lookups="products"
                :value="pageData.product_id" @input="pageData.product_id = $event" :multi="false" :required="false"
                selected_label="name" label="product" selected_prop="id" :hide-details="true"
                :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />

              <GenericInput type="select" :disabled="!(pageData.product_id)" paddingY="py-2" :clearable="true"
                :lookups="filtered_products_types" :value="pageData.product_type_id"
                @input="pageData.product_type_id = $event" :multi="false" :required="false" selected_label="name"
                :label="!pageData.product_id ? 'product type (select product first)' : 'product type'"
                selected_prop="id" :hide-details="true" :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />

              <GenericInput type="select" paddingY="py-2" :clearable="true" :lookups="filtered_producers"
                :value="pageData.producer_id" @input="pageData.producer_id = $event" :multi="false" :required="false"
                selected_label="name" label="producer" selected_prop="id" :hide-details="true"
                :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />

              <GenericInput type="select" paddingY="py-2" :clearable="true" :lookups="governorates"
                :value="pageData.gov_id" @input="pageData.gov_id = $event" :multi="false" :required="false"
                selected_label="name" label="governorate" selected_prop="id" :hide-details="true"
                :isLoading="pageData.isLoading" :cols="[6, 4, 3]" />
              <!-- :disabled="!(pageData.product_id && pageData.product_type_id && pageData.producer_id)" 
              :label="!(pageData.product_id && pageData.product_type_id && pageData.producer_id) ? 'item (select product - type - producer first)' : 'item'" -->
              <GenericInput type="select" :clearable="true" paddingY="py-2" :lookups="filtered_items"
                :value="pageData.item_id" @input="pageData.item_id = $event" label="item" selected_label="name"
                selected_prop="id" :multi="false" :required="false" :hide-details="true" :isLoading="pageData.isLoading"
                :cols="[6, 4, 3]" />

              <v-col cols="12" sm="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed color="blue4" @click="getData" :loading="pageData.isLoading" class="mx-1 white--text">
                  <v-icon size="18" left>mdi-filter-variant</v-icon>{{ $t("filter") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- End Of Filter -->

      <!-- Table -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" />
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>


      <v-dialog v-model="modifyPriceDialog" overlay-opacity="0.75" persistent :max-width="950">
        <v-card relative class="pa-7 py-10">
          <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="modifyPriceDialog = false">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
          <v-row justify="center" class="">

            <!-- Summery -->
            <ControlSummery :summery="selectedRow.summery" :shadow="false" :loading="selectedRow.loading" />
            <!-- End of Summery -->
            <v-col cols="12" class="px-4">
              <v-row v-if="!selectedRow.loading">
                <GenericInput type="float" :value="selectedRow.data.min_price"
                  @input="selectedRow.data.min_price = $event" label="minimum price" :required="false"
                  :isLoading="selectedRow.loading" :cols="[12, 6, 6]">
                </GenericInput>
                <GenericInput type="float" :value="selectedRow.data.max_price"
                  @input="selectedRow.data.max_price = $event" label="maximum price" :required="false"
                  :isLoading="selectedRow.loading" :cols="[12, 6, 6]">
                </GenericInput>
              </v-row>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn class="mx-2" text depressed color="red4" @click="modifyPriceDialog = false" :height="37"
                :width="120">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn class="mx-2 white--text" :loading="false" @click="saveModifyPrice" depressed color="success"
                :height="37" :width="120">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>


    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import ControlSummery from '@/components/ui/ControlSummery.vue';
export default {
  name: "PricesEntity",

  data: () => ({
    pageData: {
      screen_code: "05-006",
      url: null,
      controlRoute: "prices/price-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      gov_id: '',
      producer_id: '',
      product_id: '',
      item_id: '',
      product_type_id: '',
      from_date: '',
      to_date: '',
    },
    today: null,
    showFilter: false,
    items: [],
    governorates: [],
    producers: [],
    products: [],
    products_types: [],
    modifyPriceDialog: false,
    selectedRow: {
      loading: false,
      id: null,
      index: null,
      data: Object,
      summery: []
    },
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput,
    ControlSummery
  },
  watch: {
    modifyPriceDialog() {
      if (!this.modifyPriceDialog) {
        this.selectedRow = {
          loading: false,
          id: null,
          index: null,
          data: Object,
          summery: []
        }
      }
    },
    'pageData.product_id'() {
      if (!this.filtered_products_types.some((type) => type['id'] == this.pageData.product_type_id)) {
        this.pageData.product_type_id = null;
        this.pageData.producer_id = null;
        this.pageData.gov_id = null;
        this.pageData.item_id = null;
      }
    },
    'pageData.item_id'() {
      if (this.pageData.item_id) {
        const item = this.filtered_items.filter((item) => item.id === this.pageData.item_id).at(0)
        this.pageData.product_id = item.product_id;
        this.pageData.product_type_id = item.product_type_id;
        this.pageData.producer_id = item.producer_id;
        // this.pageData.gov_id = item.gov_id;
      }
    },
    'pageData.producer_id'() {
      if (!this.filtered_items.some((item) => item['id'] == this.pageData.item_id)) {
        this.pageData.item_id = null;
        this.pageData.gov_id = null;
      }
    }
  },
  computed: {
    filtered_items() {
      return this.items.filter((item) => {
        if (this.pageData.gov_id) {
          if (
            (this.pageData.product_id ? item['product_id'] == this.pageData.product_id : true)
            && (this.pageData.product_type_id ? item['product_type_id'] == this.pageData.product_type_id : true)
            && (this.pageData.producer_id ? item['producer_id'] === this.pageData.producer_id : true)
            && item['governorates'].filter((gov) => [this.pageData.gov_id].includes(gov)).length
          )
            return item
        } else {
          if (
            (this.pageData.product_id ? item['product_id'] == this.pageData.product_id : true)
            && (this.pageData.product_type_id ? item['product_type_id'] == this.pageData.product_type_id : true)
            && (this.pageData.producer_id ? item['producer_id'] === this.pageData.producer_id : true)
          )
            return item
        }
      })
    },
    filtered_governorates() {
      if (this.pageData.item_id) {
        const getItem = this.filtered_items.filter((item) => item.id === this.pageData.item_id)[0]
        console.log(getItem);
        return this.governorates.filter((governorate) => {
          if (getItem.governorates.some((item_gov) => governorate.id == item_gov))
            return governorate
        })
      } else {
        return this.governorates
      }

    },
    filtered_producers() {
      if (this.pageData.product_type_id || this.pageData.product_id) {
        let filtered = []
        this.filtered_items.forEach((item) => {
          const getProducer = this.producers.filter((producer) => producer.id === item.producer_id).at(0)
          filtered.push(getProducer)
        })
        return filtered
      } else {
        return this.producers
      }
    },
    filtered_products_types() {
      return this.products_types.filter((type) => type['product_id'] == this.pageData.product_id)
    },
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.pageData.to_date = this.$global.GetCurrentDate();
    this.pageData.from_date = this.$global.GetFirstDayOfMonth();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "producer", key: "producer_name", type: 'text', classes: "" },
        { text: "product type", key: "product_type_name", type: 'text', classes: "" },
        { text: "product", key: "product_name", type: 'text', classes: "" },
        { text: "item", key: "item_name", type: 'text', classes: "" },
        { text: "governorate", key: "governorate_name", type: 'text', classes: "" },
        { text: "date", key: "price_date", type: 'date', classes: "" },
        { text: "minimum price", key: "min_price", type: 'float', classes: "" },
        { text: "maximum price", key: "max_price", type: 'float', classes: "" },
        { text: "medium price yesterday", key: "mid_price_yesterday", type: 'float', classes: "" },
        { text: "medium price", key: "mid_price", type: 'float', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `price?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search || ''}&gov_id=${this.pageData.gov_id || ''}&producer_id=${this.pageData.producer_id || ''}&product_id=${this.pageData.product_id || ''}&product_type_id=${this.pageData.product_type_id || ''}&item_id=${this.pageData.item_id || ''}&from_date=${this.pageData.from_date || ''}&to_date=${this.pageData.to_date || ''}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              response.data.items.forEach(item => {
                item.governorates = item.govs.split(',').map(i => +i)
              });
              this.items = response.data.items;
              this.pageData.rows = response.data.data.data;
              this.governorates = response.data.governorates || [];
              this.producers = response.data.producers || [];
              this.products = response.data.products || [];
              this.products_types = response.data.products_types || [];
              this.pageData.page = response.data.data.current_page;
              this.pageData.pagination = {
                page: response.data.data.current_page,
                totalPages: response.data.data.last_page,
                per_page: response.data.data.per_page,
                totalRows: response.data.data.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`price/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`price_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/price/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      // this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      this.selectedRow.id = row.id;
      this.modifyPriceDialog = true;
      this.selectedRow.loading = true
      this.$api.GET_METHOD(`price/${row.id}`).then((response) => {
        this.selectedRow.loading = false
        if (response.check) {
          this.selectedRow.data = { ...response.data.data };
          this.selectedRow.summery = [
            {
              label: 'item',
              value: response.data.data.item_name,
              type: 'text',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'product type',
              value: response.data.data.product_type_name,
              type: 'text',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'product',
              value: response.data.data.product_name,
              type: 'text',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'producer',
              value: response.data.data.producer_name,
              type: 'text',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'governorate',
              value: response.data.data.governorate_name,
              type: 'text',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'medium price yesterday',
              value: response.data.data.mid_price_yesterday,
              type: 'float',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },
            {
              label: 'medium price',
              value: response.data.data.mid_price,
              type: 'float',
              class: '',
              cols: null, sm: null, md: null, lg: 4, xl: 4,
            },


          ]
        }
      })
    },
    saveModifyPrice() {
      this.selectedRow.loading = true;
      const requestBody = {
        min_price: this.selectedRow.data.min_price,
        max_price: this.selectedRow.data.max_price,
        _method: 'PUT'
      }
      this.$api.POST_METHOD(`price/${this.selectedRow.id}`, requestBody).then((response) => {
        this.selectedRow.loading = false;
        if (response.check) {
          this.modifyPriceDialog = false;
          this.getData();
        }
      })
    }
  },
};
</script>
