<template>
  <section>
    <v-container fluid class="pa-0">
      <v-container fluid class="pb-0 mt-2 px-5">
        <v-card class="ma-0 shadow pa-5 d-flex align-center rounded-xl" style="min-height: 150px">
          <v-avatar
            size="50px"
            class="rounded-circle pa-7"
            :style="$store.state.Settings.darkTheme == true ? 'border: 4px solid #181818;' : 'border: 4px solid #eeeeee;'"
          >
            <img
              v-if="$store.state.userData.image"
              class="pa-1"
              :src="$api.serverUrl + $store.state.userData.image"
              :alt="$store.state.userData.full_name"
            />
            <v-icon v-if="!$store.state.userData.image">mdi-file-image-outline</v-icon>
            <img
              :alt="$store.state.userData.full_name"
              v-if="!$store.state.userData.image"
              src="@/assets/img/svg/Vector.svg"
            />
            <v-icon></v-icon>
          </v-avatar>
          <h5 class="text-h5 mx-2">
            <strong>{{ $t('Welcome') }} {{ $store.state.userData.full_name }}</strong>
            <div class="subtitle-1 text--disabled">
              <v-icon small class="text--disabled">mdi-hand-wave</v-icon>
              {{ $t('Happy activity') }} - {{ greeting }}
              {{
                new Date(date).toLocaleDateString(
                  `${$vuetify.rtl == true ? "ar-EG" : "en-UK"}`,
                  {
                    weekday: "short",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }
                )
              }}
            </div>
          </h5>
        </v-card>
      </v-container>
      <StatisticsBar></StatisticsBar>
      <v-col class="col-12">
        <v-row>
          <v-col cols="12" lg="8" sm="12" order="5" order-lg="6">
            <AnalysisChart />
            <MaterialPriceTable :isLoading="false" :data="cement" title="Cement" />
            <MaterialPriceTable :isLoading="false" :data="metal" title="Metal" />
            <MaterialPriceTable :isLoading="false" :data="gypsum" title="Gypsum" />
          </v-col>
          <v-col cols="12" lg="4" sm="12" order="5" order-lg="6">
            <v-row>
              <!-- Statistics -->
              <v-col cols="12" lg="12" sm="6">
                <CountriesStatistics :isLoading="false" :data="statistics" title="Cement" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </section>
</template>

<script>
import StatisticsBar from "@/components/StatisticsBar.vue";
import MaterialPriceTable from "@/components/MaterialPriceTable.vue";
import CountriesStatistics from "@/components/CountriesStatistics.vue";
import AnalysisChart from "@/components/AnalysisChart.vue";
export default {
  name: "Home",
  computed: {

  },
  components: {
    StatisticsBar,
    MaterialPriceTable,
    CountriesStatistics,
    AnalysisChart
  },
  data() {
    return {
      date: new Date(),
      greeting: '',
      cement: [
        {
          id: 1,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 2,
          producer: 'Cemex',
          product: 'Al-nasr',
          lowest_price: '1090 L.E',
          highest_price: '1091 L.E',
          today_avg: '1090 L.E',
          yesterday_avg: '1093 L.E',
          change: '3 L.E',
        },
        {
          id: 3,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 4,
          producer: 'Al-nahda',
          product: 'Alshkara',
          lowest_price: '1080 L.E',
          highest_price: '1088 L.E',
          today_avg: '1081 L.E',
          yesterday_avg: '1083 L.E',
          change: '13 L.E',
        },
        {
          id: 5,
          producer: 'Wadi Nile',
          product: 'Al-masry',
          lowest_price: '1000 L.E',
          highest_price: '1020 L.E',
          today_avg: '1050 L.E',
          yesterday_avg: '1055 L.E',
          change: '20 L.E',
        },
        {
          id: 6,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
      ],
      metal: [
        {
          id: 1,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 2,
          producer: 'Cemex',
          product: 'Al-nasr',
          lowest_price: '1090 L.E',
          highest_price: '1091 L.E',
          today_avg: '1090 L.E',
          yesterday_avg: '1093 L.E',
          change: '3 L.E',
        },
        {
          id: 3,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 4,
          producer: 'Al-nahda',
          product: 'Alshkara',
          lowest_price: '1080 L.E',
          highest_price: '1088 L.E',
          today_avg: '1081 L.E',
          yesterday_avg: '1083 L.E',
          change: '13 L.E',
        },
        {
          id: 5,
          producer: 'Wadi Nile',
          product: 'Al-masry',
          lowest_price: '1000 L.E',
          highest_price: '1020 L.E',
          today_avg: '1050 L.E',
          yesterday_avg: '1055 L.E',
          change: '20 L.E',
        },
        {
          id: 6,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },

      ],
      gypsum: [
        {
          id: 1,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 2,
          producer: 'Cemex',
          product: 'Al-nasr',
          lowest_price: '1090 L.E',
          highest_price: '1091 L.E',
          today_avg: '1090 L.E',
          yesterday_avg: '1093 L.E',
          change: '3 L.E',
        },
        {
          id: 3,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },
        {
          id: 4,
          producer: 'Al-nahda',
          product: 'Alshkara',
          lowest_price: '1080 L.E',
          highest_price: '1088 L.E',
          today_avg: '1081 L.E',
          yesterday_avg: '1083 L.E',
          change: '13 L.E',
        },
        {
          id: 5,
          producer: 'Wadi Nile',
          product: 'Al-masry',
          lowest_price: '1000 L.E',
          highest_price: '1020 L.E',
          today_avg: '1050 L.E',
          yesterday_avg: '1055 L.E',
          change: '20 L.E',
        },
        {
          id: 6,
          producer: 'Arabian Cement Co.',
          product: 'Almoslah',
          lowest_price: '1084 L.E',
          highest_price: '1090 L.E',
          today_avg: '1074 L.E',
          yesterday_avg: '1073 L.E',
          change: '0 L.E',
        },

      ],
      statistics: [
        {
          id: 1,
          gov: 'cairo',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 2,
          gov: 'Alexandria',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 3,
          gov: 'Gharbia',
          status: 'down',
          percentage: '3',
        },
        {
          id: 4,
          gov: 'Sharkia',
          status: 'up',
          percentage: '1',
        },
        {
          id: 5,
          gov: 'Menoufia',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 5,
          gov: 'Beheira',
          status: 'down',
          percentage: '3',
        },
        {
          id: 6,
          gov: 'Suez',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 7,
          gov: 'Port Said',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 8,
          gov: 'Giza',
          status: 'down',
          percentage: '5',
        },
        {
          id: 9,
          gov: 'Assiut',
          status: 'up',
          percentage: '3',
        },
        {
          id: 9,
          gov: 'Qena',
          status: 'normal',
          percentage: '0',
        },
        {
          id: 10,
          gov: 'Fayoum',
          status: 'down',
          percentage: '4',
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.getHomeData()
  },
  methods: {
    getHomeData() {
      let time = new Date().getHours();
      if (time >= 0 && time < 12) {
        this.greeting = this.$i18n.t("Good Morning");
      } else if (time == 12) {
        this.greeting = this.$i18n.t("Good Noon");
      } else if (time >= 12 && time <= 17) {
        this.greeting = this.$i18n.t("Good Afternoon");
      } else {
        this.greeting = this.$i18n.t("Good Evening");
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
