<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" md="6" lg="4" class="px-0">
                     <GenericInput type="file" :value="form.photo" @input="form.photo = $event"
                        :cols="[12, 12, 12]" label="image" :multi="false" :required="true"
                        :isLoading="pageData.editIsLoading">
                     </GenericInput>
                  </v-col>
                  <v-col cols="1" class="pa-0 py-10 d-none d-md-block">
                     <v-divider vertical class="d-block ma-auto"></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" lg="7" class="d-flex align-center">
                     <v-row align="center">
                        <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event"
                           label="الأسم بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event"
                           label="name in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="textarea" :value="form.description_ar" @input="form.description_ar = $event"
                           label="الوصف بالعربي" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                        <GenericInput type="textarea" :value="form.description_en" @input="form.description_en = $event"
                           label="description in english" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                     </v-row>
                  </v-col>


               </v-row>


            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "ProducersControl",
   data: () => ({
      pageData: {
         screen_code: "05-002",
         url: null,
         controlRoute: "prices/producer-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         name_en: null,
         name_ar: null,
         description_en: null,
         description_ar: null,
         photo: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`producer/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`producer/create`).then(() => {
                  this.pageData.isLoading = false;
                  // if (response.check) {
                  //    this.titles = response.data.titles;
                  // }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`producer/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`producer`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`producer/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
