<template>
  <v-card elevation="0" class="rounded-xl pa-4 mt-4 ">
    <div class="d-flex align-center justify-space-between">
      <div class="subtitle-1 font-weight-medium">{{ $t("Materials prices") }}</div>
      <!-- <div class="subtitle-2 primary--text text-decoration-underline">
        {{ $t("see more") }}
      </div> -->
    </div>
    <div class="subtitle-1 font-weight-bold">{{ $t(title) }}</div>
    <div class="col-12 px-0 py-1 tableCol">
      <table class="materials-prices-table relative overflow-hidden">
        <thead>
          <!-- header -->
          <tr class="text-center">
            <th>{{ $t("Producer") }}</th>
            <th>{{ $t("Product") }}</th>
            <th class="font-weight-bold">{{ $t("Lowest Price") }}</th>
            <th class="font-weight-bold">{{ $t("Highest Price") }}</th>
            <th v-if="comparison == false">{{ $t("Today avg") }}</th>
            <th v-if="comparison == false">{{ $t("Yesterday avg") }}</th>
            <th v-if="comparison == false">{{ $t("Change") }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- row -->
          <tr class="text-center" v-for="(row, index) in data" :key="index">
            <td>{{ row.producer }}</td>
            <td>{{ row.product }}</td>
            <td class="font-weight-bold">{{ row.lowest_price }}</td>
            <td class="font-weight-bold">{{ row.highest_price }}</td>
            <td v-if="comparison == false">{{ row.today_avg }}</td>
            <td v-if="comparison == false">{{ row.yesterday_avg }}</td>
            <td v-if="comparison == false">{{ row.change }}</td>
          </tr>
        </tbody>
        <!-- <div class="water-mark"></div> -->
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MaterialPriceTable",
  props: {
    // backValueMethod: { type: Function },
    isLoading: { default: false },
    comparison: { default: false },
    data: { default: [] },
    title: { default: "" },
  },
  components: {},
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tableCol {
  @media screen and (max-width: 720px) {
    overflow-x: auto;
  }
}
.materials-prices-table {
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;
  @media screen and (max-width: 720px) {
    width: 1000px;
  }
  border: 0.704431px solid #c4c4c4;
  thead {
    th {
      border: 0;
      line-height: 3rem;
    }
  }
  tbody {
    tr {
      border: 0;
      border-color: transparent;
      &:nth-of-type(even) {
        background: #fff;
      }
      &:nth-of-type(odd) {
        background: rgba(0, 0, 0, 0.05);
      }
      td {
        border: 0;
        line-height: 3rem;
      }
    }
  }
}

.water-mark {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: url("../assets/logo.png");
  background-repeat: repeat;
  transform: rotate(45deg) scale(1.5, 1.5);
  opacity: 0.07;
}
</style>
