<template>
  <div>
    <v-text-field :solo="solo" outlined dense :name="name" :disabled="disabled" v-model="floatNumber" class="v-price rounded-lg"
      :class="{ 'v-price-active': !price_input}" :loading="isLoading" rounded-md :color="color"
      :rules="required == true ? $global.requiredNumberRule : $global.notRequiredRule"
      :hide-details="hideDetails == true ? 'auto' : false">
      <template v-slot:label>
        <label v-if="labelInline == false">
          {{ $t(label) }}
          <span class="error--text" v-if="required == true && label">*</span>
        </label>
      </template>
      <template v-slot:prepend>
        <input type="number" class="inputPrice" :disabled="disabled" :class="{ 'opacity-0': !price_input , 'active': price_input}" @focus="price_input = true"
          @focusout="price_input = false" v-model="input">
      </template>
      <template v-slot:append>
        <v-icon class="position-relative cursor_pointer" v-if="clearable" @click="input = 0"
          style="z-index: 5;">mdi-close</v-icon>
      </template>
    </v-text-field>
  </div>

</template>


<script>
export default {
  name: "FloatInput",
  props: {
    backValueMethod: { type: Function },
    clickEnter: { type: Function },
    value: { default: 0 },
    disabled: { default: false },
    disabledDot: { default: false },
    number: { default: false },
    autofocus: { default: false },
    name: { default: null },
    label: { default: null },
    required: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    onChange: { type: Function },
    isLoading: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false },
    keydownEnter: { type: Function },
    solo: { default: false },
    classes: { default: 'rounded-lg' },

  },
  data: () => ({
    price_input: false,
  }),
  computed: {
    input: {
      get() {
        return this.value || 0;
      },
      set(val) {
        this.$emit("input", +val);
      },
    },
    floatNumber() {
      return this.$global.NumberWithCommas(this.input.toFixed(2))
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },


  methods: {

  },
};
</script>



<style  lang="scss">
.v-price-active.v-input input:not(.inputPrice) {
  opacity: 1 !important;
}

.v-price.v-input input {
  opacity: 0;
}

.v-price .v-input__prepend-outer {
  margin-left: 0;
}

.inputPrice {
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 4px;
  &.active{
    opacity: 1 !important;
  }
}

.v-application--is-rtl {
  .inputPrice {
    left: unset;
    right: 10px;
  }
}
</style>
