<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">

                  <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event"
                     label="العنوان بالعربي" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event"
                     label="title in english" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="subscription_types" :value="form.subscription_type_id"
                     @input="form.subscription_type_id = $event" label="type" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" v-if="form.subscription_type_id && form.subscription_type_id != 1"
                     :lookups="governorates" :value="form.govs" @input="form.govs = $event" label="governorates"
                     selected_label="name" selected_prop="id" :multi="true" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="select" v-if="form.subscription_type_id && form.subscription_type_id != 1"
                     :lookups="price_types" :value="form.price_type_id" @input="form.price_type_id = $event"
                     label="price type" selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="number" v-if="form.subscription_type_id && form.subscription_type_id != 1"
                     :value="form.number_of_emails" @input="form.number_of_emails = $event" label="number of emails"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>


                  <GenericInput type="number" :value="form.price" @input="form.price = $event" label="price"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.duration" @input="form.duration = $event" label="duration"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <v-col cols="6"></v-col>
                  <GenericInput type="textarea" :value="form.description_ar" @input="form.description_ar = $event"
                     label="الوصف بالعربي" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="textarea" :value="form.description_en" @input="form.description_en = $event"
                     label="description in english" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>


               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "SubscriptionsControl",
   data: () => ({
      pageData: {
         screen_code: "06-001",
         url: null,
         controlRoute: "main/subscription-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      governorates: [],
      price_types: [],
      subscription_types: [],
      form: {
         title_en: null,
         title_ar: null,
         description_en: null,
         description_ar: null,
         price: null,
         duration: null,
         subscription_type_id: null,
         govs: null,
         number_of_emails: null,
         price_type_id: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`subscription/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.subscription_types = response.data.subscription_types;
                     this.governorates = response.data.governorates || [];
                     this.price_types = response.data.price_types || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`subscription/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.subscription_types = response.data.subscription_types;
                     this.governorates = response.data.governorates || [];
                     this.price_types = response.data.price_types || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`subscription/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`subscription`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`subscription/${this.form.subscription_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
