<template>
  <div>
    <v-main v-if="$store.state.splashScreen == false">
      <v-container fluid class="px-2">
        <v-row>
          <v-expand-x-transition>
            <v-navigation-drawer v-if="$vuetify.breakpoint.width >= 991 && !$store.state.topAppBar" class="shadow-none  border-0" style="z-index : 66"
              :right="$vuetify.rtl == true" mini-variant-width="80" :mini-variant.sync="$store.state.sidebarIsOpen"
              :mobile-breakpoint="991"  app>
              <Sidebar></Sidebar>
            </v-navigation-drawer>
          </v-expand-x-transition>



          <v-navigation-drawer v-if="$vuetify.breakpoint.width <= 991" class="shadow-none pa-0 border-0" style="z-index : 66" :right="$vuetify.rtl == true"
             v-model="$store.state.sidebarIsOpen" app>
            <Sidebar></Sidebar>
          </v-navigation-drawer>


          <Appbar />

          <v-container fluid class="pa-0">
            <router-view></router-view>
          </v-container>
        </v-row>
      </v-container>


      <v-snackbar multi-line top :left="!$vuetify.rtl" color="gray3" :right="$vuetify.rtl"
        v-model="$store.state.showSnackbar" :timeout="4000">
        <div :class="`${$store.state.snackbarType}--text`">{{ $t($store.state.snackbarTitle) }}</div>
        <div class="body-1" v-for="(message, index) in $store.state.snackbarMessages" :key="index">{{ $t(message) }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn :color="$store.state.snackbarType" text v-bind="attrs" @click="$store.state.showSnackbar = false">
            {{ $t('hide') }}
          </v-btn>
        </template>
      </v-snackbar>


    </v-main>
  </div>

</template>


<script>
import Appbar from "@/components/ui/Appbar.vue";
import Sidebar from "@/components/ui/Sidebar.vue";
export default {
  name: "Dashboard",
  components: {
    Appbar,
    Sidebar,
  },
  mounted() {
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET();
      this.$store.state.networkConnection = this.$api.CHECK_INTERNET();
    }, 2000);
  },
  data() {
    return {
      mini: false,
      darkTheme: false,
      networkConnection: Boolean
    }
  },
  computed: {
    showSnackbar() {
      return this.$store.state.showSnackbar
    }
  },
  watch: {
    showSnackbar() {
      if (!this.showSnackbar) {
        setTimeout(() => {
          this.$store.state.snackbarType = 'info';
          this.$store.state.snackbarTime = 4500;
          this.$store.state.snackbarTitle = null;
          this.$store.state.snackbarMessages = [];
        }, 100);
      }
    }
  },
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
<style scoped>

</style>