<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">


                  <GenericInput type="select" :lookups="governorates" :value="form.governorate_id"
                     @input="form.governorate_id = $event" label="governorate" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="products" :value="form.product_id"
                     @input="form.product_id = $event" label="product" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="select" :disabled="!(form.product_id)" :lookups="filtered_products_types"
                     :value="form.product_type_id" @input="form.product_type_id = $event"
                     :label="!form.product_id ? 'product type (select product first)' : 'product type'"
                     selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="select" :lookups="producers" :value="form.producer_id"
                     @input="form.producer_id = $event" label="producer" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="filtered_items" :value="form.item_id"
                     :label="!(form.product_id && form.product_type_id && form.producer_id) ? $i18n.t('item (select product - type - producer first)') : $i18n.t('item')"
                     @input="form.item_id = $event" selected_label="name" selected_prop="id" :multi="false"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="units" :value="form.unit_id" @input="form.unit_id = $event"
                     label="unit" selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.quantity" @input="form.quantity = $event" label="quantity"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "DealsControl",
   data: () => ({
      pageData: {
         screen_code: "03-003",
         url: null,
         controlRoute: "others/deal-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      producers: [],
      governorates: [],
      products: [],
      products_types: [],
      items: [],
      units: [],
      form: {
         item_id: null,
         product_id: null,
         product_type_id: null,
         producer_id: null,
         governorate_id: null,
         unit_id: null,
         quantity: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
      filtered_products_types() {
         return this.products_types.filter((type) => type['product_id'] == this.form.product_id)
      },
      filtered_items() {
         console.log('form', this.form);
         return this.items.filter((item) =>
            item['product_id'] == this.form.product_id
            && item['product_type_id'] == this.form.product_type_id
            && item['producer_id'] == this.form.producer_id
         )
      },
   },
   watch: {
      "form.product_id"() {
         if (this.pageData.isEdit == false) {
            this.form.product_type_id = null;
         }
      },
      "form.product_type_id"() {
         if (this.pageData.isEdit == false) {
            this.form.item_id = null;
         }
      },
      "form.producer_id"() {
         if (this.pageData.isEdit == false) {
            this.form.item_id = null;
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.formatDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`deal/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.items = response.data.items || [];
                     this.products = response.data.products || [];
                     this.products_types = response.data.products_types || [];
                     this.producers = response.data.producers || [];
                     this.governorates = response.data.governorates || [];
                     this.units = response.data.units || [];
                     // this.form.govs = this.form.govs.split(',').map(i => Number(i));
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`deal/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.items = response.data.items || [];
                     this.products = response.data.products || [];
                     this.products_types = response.data.products_types || [];
                     this.producers = response.data.producers || [];
                     this.governorates = response.data.governorates || [];
                     this.units = response.data.units || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`deal/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`deal`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      formatDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         this.form.deal_date = [year, month, day].join('-');
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`deal/${this.form.deal_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
